<template>
	<div>
		<multiselect
			v-model="selected"
			:class="validateError != '' ? 'box-border-color' : ''"
			:clear-on-select="!multiple"
			:close-on-select="!multiple"
			:deselect-label="''"
			:multiple="multiple"
			:options="options"
			:placeholder="$t('select')"
			:select-label="''"
			:selected-label="''"
			label="text"
			track-by="value"
			@input="handleInput($event, multiple, multipleReturnType)"
		>
			<template v-if="multiple"
			          slot="selection"
			          slot-scope="{ values, search, isOpen}"
			>
                <span v-if="values.length && !isOpen" class="multiselect__single">
	                {{ translateNSelected(values,10) }}
                </span>
			</template>
			<span slot="noOptions">
				{{ $t("no_options") }}
            </span>
			<span slot="noResult">
                {{ $t("no_result") }}
            </span>
		</multiselect>
	</div>
</template>

<script>
	// Helpers
	import translateNSelected from "@/helpers/translateNSelected";
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";

	// Services
	import GradeService from "@/services/GradeService";
    import CourseService from "@/services/CourseService";
    import qs from "qs";

	export default {
		props: {
			value: {
				default: null,
			},
			validateError: {
				type: String,
				default: "",
			},
			multiple: {
				type: Boolean,
				default: false,
			},
			multipleReturnType: {
				default: 'withComma'
			},
            course_id: {
                default: null,
            },
		},
		watch: {
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options, this.multiple, this.multipleReturnType)
			},
            course_id: {
                handler: function (newValue, oldValue) {
                    if (newValue != oldValue) {
                        this.getCourseId(this.course_id)
                    }
                }
            },
		},
		data() {
			return {
                grading_system_id: null,
				selected: null,
				options: [],
			};
		},
		created() {
			this.getOptions();
		},
		methods: {
            getCourseId(id){
                CourseService.get(id).then((response)=>{
                    this.grading_system_id = response.data.data.grading_system_id
                    this.getOptions()
                })
            },
			translateNSelected: translateNSelected,
			handleInput: handleInput,
			setSelected: setSelected,
			getOptions() {
			    if(this.grading_system_id == null){
                    return;
                }

				const config = {
					params: {
						limit: -1,
                        filter: {
                            grading_system_id: this.grading_system_id
                        },
					},
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
				};
				this.options = [];
				GradeService.getAll(config)
				            .then((response) => {
					            const data = response.data.data;
					            data.map((item) => {
						            this.options.push({
							            value: item.id,
							            text: item.grade,
						            });
					            });
				            })
				            .then(() => {
					            this.selected = this.setSelected(this.value, this.options, this.multiple, this.multipleReturnType)
				            })
			}
		}
	}
</script>
