<template>
    <div>
            <ValidationObserver ref="formModalValidate">
                    <b-row class="mt-2">
                        <b-col cols="12" md="12">
                            <ValidationProvider name="course_id"
                                                rules="required"
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('course')">
                                    <course-auto-complete  v-model="formData.course_id"
                                                  :state="errors[0] ? false : null"/>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="grade_id"
                                                rules="required"
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('grade_id')">
                                    <grade-id-selectbox :course_id="formData.course_id" v-model="formData.grade_id"
                                                  :state="errors[0] ? false : null"/>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                            <div class="d-flex justify-content-center mt-3 mb-3">
                                <b-button variant="primary"
                                          @click="createForm"
                                          :disabled="formLoading">
                                    {{ $t('save') }}
                                </b-button>
                            </div>
                        </b-col>


                    </b-row>
            </ValidationObserver>
    </div>
</template>

<script>
// Components
import GradeIdSelectbox from "@/components/interactive-fields/GradeIdSelectbox";

// Services
import CertificateCourseService from "@/services/CertificateCourseService";

// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import CourseAutoComplete from "@/components/interactive-fields/CourseAutoComplete";



export default {
    props:{
        certificate_id:{

        }
    },
    components: {
        GradeIdSelectbox,
        CourseAutoComplete,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            type:"edr",
            formData: {
                // Tab 1
            },
            formLoading: false
        }
    },
    methods: {
        async createForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                this.formLoading = true;
                this.formData.certificate_id=this.certificate_id
                let formData = {
                    ...this.formData
                }
                CertificateCourseService.store(formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$emit('createFormSuccess', true);
                    })
                    .catch(e => {
                        if (e.status == '422') {
                            let errors = e.data.errors;
                        }
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false;
                    })
            }
        }
    }
}
</script>
